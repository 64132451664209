import axios from '@/plugins/axios'
import { getAccountId } from '@/helper/auth-helper'

/**
 *  'target' on back-end -> 'destination' on front-end
 */

const getDestinationsForManager = params => axios.get(`${getAccountId()}/targets/paginated`, { params })
const getDestinationsForBuyer = params => axios.get(`${getAccountId()}/buyer/targets/paginated`, { params })
const getAllDestinations = () => axios.get(`${getAccountId()}/targets/all`)
const getDetailDestination = destinationId => axios.get(`${getAccountId()}/targets/${destinationId}`)
const getCountersForManager = params => axios.get(`${getAccountId()}/targets/counters`, { params })
const getCountersForBuyer = params => axios.get(`${getAccountId()}/buyer/targets/counters`, { params })
const getCapOnOptions = () => axios.get('targets/cap-on-options')
const getConversionOptions = () => axios.get('targets/conversion-options')
const getDuplicateRevenueOptions = () => axios.get('targets/duplicate-revenue-options')
const createDestination = formData => axios.post(`${getAccountId()}/targets`, formData)
const resetDestinationCaps = destinationId => axios.delete(`${getAccountId()}/targets/${destinationId}/caps`)
const changeDestinationStatus = ({ destinationId, formData }) => axios.patch(`${getAccountId()}/targets/${destinationId}/status`, formData)
const editDestinationForManager = ({ destinationId, formData }) => axios.patch(`${getAccountId()}/targets/${destinationId}`, formData)
const editDestinationForBuyer = ({ destinationId, formData }) => axios.patch(`${getAccountId()}/buyer/targets/${destinationId}`, formData)
const deleteDestination = destinationId => axios.delete(`${getAccountId()}/targets/${destinationId}`)

export {
  getDestinationsForManager, getDestinationsForBuyer, getAllDestinations, getDetailDestination, getCountersForManager, getCountersForBuyer, getCapOnOptions, getConversionOptions, getDuplicateRevenueOptions, createDestination, resetDestinationCaps, changeDestinationStatus, editDestinationForManager, editDestinationForBuyer, deleteDestination
}